// import { Icon } from '@iconify/react';
// import React, { useState } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

// export default function AppointmentForm() {
//   const [selectedDate, setSelectedDate] = useState(null);
//   return (
//     <form action="#" className="row">
//       <div className="col-lg-6">
//         <label className="cs_input_label cs_heading_color">Name</label>
//         <input type="text" className="cs_form_field" placeholder="David John" />
//         <div className="cs_height_42 cs_height_xl_25" />
//       </div>
//       <div className="col-lg-6">
//         <label className="cs_input_label cs_heading_color">Phone Number</label>
//         <input
//           type="text"
//           className="cs_form_field"
//           placeholder="(123) 456 - 789"
//         />
//         <div className="cs_height_42 cs_height_xl_25" />
//       </div>
//       <div className="col-lg-12">
//         <label className="cs_input_label cs_heading_color">
//           Medical Record Number
//         </label>
//         <input
//           type="text"
//           className="cs_form_field"
//           placeholder="123456-7890-0987"
//         />
//         <div className="cs_height_42 cs_height_xl_25" />
//       </div>
//       <div className="col-lg-6">
//         <label className="cs_input_label cs_heading_color">
//           Preferred Date
//         </label>

//         <div className="cs_with_icon_input">
//           <DatePicker
//             selected={selectedDate}
//             onChange={date => setSelectedDate(date)}
//             dateFormat="dd/MM/yyyy"
//             minDate={new Date()}
//             isClearable
//             placeholderText="dd/mm/yyyy"
//           />
//           <i>
//             <Icon icon="fa6-solid:calendar-days" />
//           </i>
//         </div>
//         <div className="cs_height_42 cs_height_xl_25" />
//       </div>
//       <div className="col-lg-6">
//         <label className="cs_input_label cs_heading_color">
//           Preferred Time
//         </label>
//         <div className="cs_with_icon_input">
//           <input
//             type="time"
//             className="cs_form_field cs_timepicker"
//             placeholder="10:00AM"
//           />
//           <i>
//             <Icon icon="fa6-regular:clock" />
//           </i>
//         </div>
//         <div className="cs_height_42 cs_height_xl_25" />
//       </div>
//       <div className="col-lg-12">
//         <label className="cs_input_label cs_heading_color">
//           Reason for Visit
//         </label>
//         <div className="cs_radio_group">
//           <div className="cs_radio_wrap">
//             <input
//               className="cs_radio_input"
//               type="radio"
//               name="reasonForVisit"
//               id="routineCheckup"
//               defaultValue="routineCheckup"
//             />
//             <label className="cs_radio_label" htmlFor="routineCheckup">
//               Routine Checkup
//             </label>
//           </div>
//           <div className="cs_radio_wrap">
//             <input
//               className="cs_radio_input"
//               type="radio"
//               name="reasonForVisit"
//               id="newPatientVisit"
//               defaultValue="newPatientVisit"
//               defaultChecked=""
//             />
//             <label className="cs_radio_label" htmlFor="newPatientVisit">
//               New Patient Visit
//             </label>
//           </div>
//           <div className="cs_radio_wrap">
//             <input
//               className="cs_radio_input"
//               type="radio"
//               name="reasonForVisit"
//               id="specificConcern"
//               defaultValue="specificConcern"
//             />
//             <label className="cs_radio_label" htmlFor="specificConcern">
//               Specific Concern
//             </label>
//           </div>
//         </div>
//         <div className="cs_height_42 cs_height_xl_25" />
//       </div>
//       <div className="col-lg-12">
//         <label className="cs_input_label cs_heading_color">Department</label>
//         <div className="cs_radio_group">
//           <div className="cs_radio_wrap">
//             <input
//               className="cs_radio_input"
//               type="radio"
//               name="department"
//               id="pediatric"
//               defaultValue="pediatric"
//             />
//             <label className="cs_radio_label" htmlFor="pediatric">
//               Pediatric
//             </label>
//           </div>
//           <div className="cs_radio_wrap">
//             <input
//               className="cs_radio_input"
//               type="radio"
//               name="department"
//               id="obstetricsGynecology"
//               defaultValue="obstetricsGynecology"
//               defaultChecked=""
//             />
//             <label className="cs_radio_label" htmlFor="obstetricsGynecology">
//               Obstetrics and Gynecology
//             </label>
//           </div>
//           <div className="cs_radio_wrap">
//             <input
//               className="cs_radio_input"
//               type="radio"
//               name="department"
//               id="cardiology"
//               defaultValue="cardiology"
//             />
//             <label className="cs_radio_label" htmlFor="cardiology">
//               Cardiology
//             </label>
//           </div>
//           <div className="cs_radio_wrap">
//             <input
//               className="cs_radio_input"
//               type="radio"
//               name="department"
//               id="neurology"
//               defaultValue="neurology"
//             />
//             <label className="cs_radio_label" htmlFor="neurology">
//               Neurology
//             </label>
//           </div>
//         </div>
//         <div className="cs_height_42 cs_height_xl_25" />
//       </div>
//       <div className="col-lg-12">
//         <button className="cs_btn cs_style_1">
//           <span>Submit</span>
//           <i>
//             <img src="/images/icons/arrow_white.svg" alt="Icon" />
//             <img src="/images/icons/arrow_white.svg" alt="Icon" />
//           </i>
//         </button>
//       </div>
//     </form>
//   );
// }


import { Icon } from '@iconify/react';
import React from 'react';

export default function ContactInfo() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      padding: '20px',
      maxWidth: '600px',
      margin: 'auto',
    }}>
      {/* <h2 style={{ textAlign: 'center', color: '#333', marginBottom: '15px' }}>Contact Us</h2> */}
      <p style={{ textAlign: 'center', color: '#777', fontSize: '14px' }}>We are available 24/7 to assist you. Reach out to us via phone or WhatsApp.</p>

      <a href="tel:+917977412580">
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px', padding: '10px', backgroundColor: '#fff', borderRadius: '8px' }}>
          <Icon icon="fa6-solid:phone" style={{ fontSize: '30px', color: '#007BFF' }} />
          <p style={{ margin: 0, fontSize: '16px', color: '#555', fontWeight: 'bold' }}>Call Now</p>
        </div>
      </a>

      <a href="https://wa.me/message/BLVHM5UOBIY6L1">
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px', padding: '10px', backgroundColor: '#fff', borderRadius: '8px' }}>
          <Icon icon="fa6-brands:whatsapp" style={{ fontSize: '30px', color: '#25D366' }} />
          <p style={{ margin: 0, fontSize: '16px', color: '#555', fontWeight: 'bold' }}>Chat on WhatsApp</p>
        </div>
      </a>
    </div>
  );
}

