import React from 'react';
import Hero from '../Hero';
import AboutSection from '../Section/AboutSection';
// import BrandsSection from '../Section/BrandsSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
// import FeaturesSection from '../Section/FeaturesSection';
import TestimonialSection from '../Section/TestimonialSection';
// import BlogSection from '../Section/BlogSection';
import AppointmentSection from '../Section/AppointmentSection';
import FaqSection from '../Section/FaqSection';
// import AwardSection from '../Section/AwardSection';
import DepartmentSection from '../Section/DepartmentSection';
import { pageTitle } from '../../helpers/PageTitle';
// const featureListData = [
//   {
//     iconSrc: '/images/home_1/compassion.svg',
//     title: 'Compassion',
//     subTitle:
//       'We understand that seeking medical care can be a stressful and emotional experience, and we strive to create a welcoming and supportive environment that puts our patients at ease and every one.',
//   },
//   {
//     iconSrc: '/images/home_1/excellence.svg',
//     title: 'Excellence',
//     subTitle:
//       'We are committed to providing excellent medical care and services to our patients. We believe in continuously improving our skills, knowledge, and resources to ensure that we deliver the highest quality care possible.',
//   },
//   {
//     iconSrc: '/images/home_1/integrity.svg',
//     title: 'Integrity',
//     subTitle: `We believe in practicing medicine with integrity and honesty. We are transparent in our communication and decision-making processes, and we always put our patient's interests first & provide best solution.`,
//   },
//   {
//     iconSrc: '/images/home_1/respect.svg',
//     title: 'Respect',
//     subTitle:
//       'We treat all individuals with respect and dignity, regardless of their background, beliefs, or circumstances. We believe that every person deserves to be treated with compassion and kindness.',
//   },
//   {
//     iconSrc: '/images/home_1/teamwork.svg',
//     title: 'Teamwork',
//     subTitle:
//       'We believe in working collaboratively with our team membersvand other healthcare professionals to provide comprehensive and effective care to our patients.',
//   },
// ];
// const brandData = [
//   { imgUrl: 'images/brand_1.png', imgAlt: 'Brand' },
//   { imgUrl: 'images/brand_2.png', imgAlt: 'Brand' },
//   { imgUrl: 'images/brand_3.png', imgAlt: 'Brand' },
//   { imgUrl: 'images/brand_4.png', imgAlt: 'Brand' },
//   { imgUrl: 'images/brand_5.png', imgAlt: 'Brand' },
//   { imgUrl: 'images/brand_6.png', imgAlt: 'Brand' },
//   { imgUrl: 'images/brand_7.png', imgAlt: 'Brand' },
//   { imgUrl: 'images/brand_8.png', imgAlt: 'Brand' },
// ];
const faqData = [
  {
    title: 'What services does Carepulse Home Care provide?',
    content:
      'Carepulse offers a wide range of home care services, including elderly care, post-surgical care, nursing care, physiotherapy, medication management, and assistance with daily living activities. Our trained caregivers and medical professionals ensure comfort, safety, and quality healthcare at home.',
  },
  {
    title: 'How do I know if home care is right for my loved one?',
    content:
      'Home care is ideal for individuals who need medical assistance, companionship, or help with daily tasks but prefer to stay in their own home. Whether it’s short-term recovery or long-term elderly care, we tailor our services to meet specific needs.',
  },
  {
    title: 'Are your caregivers and nurses qualified?',
    content:
      'Yes, all our caregivers, nurses, and healthcare professionals are highly trained, experienced, and certified. We conduct thorough background checks and regular training to ensure the highest standards of care and professionalism.',
  },
  {
    title: 'What should I bring to my appointment?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'How can I arrange home care services with Carepulse?',
    content:
      'You can contact us via phone or our website to schedule a consultation. We will assess your needs and create a customized care plan. Our team ensures a smooth and quick onboarding process so your loved one receives immediate care.',
  },
];
// const blogData = [
//   {
//     title: 'The Benefits of Mindfulness Meditation for Stress and Anxiety',
//     thumbUrl: 'images/home_1/post_1.jpeg',
//     date: 'May 1, 2023',
//     btnText: 'Learn More',
//     href: '/blog/blog-details',
//     socialShare: true,
//   },
//   {
//     title: 'Healthy Eating on a Budget: Tips and Strategies',
//     thumbUrl: 'images/home_1/post_2.jpeg',
//     date: 'May 4, 2023',
//     btnText: 'Learn More',
//     href: '/blog/blog-details',
//     socialShare: true,
//   },
//   {
//     title: 'The Importance of Regular Cancer Screenings and Early Detection',
//     thumbUrl: 'images/home_1/post_3.jpeg',
//     date: 'May 1, 2023',
//     btnText: 'Learn More',
//     href: '/blog/blog-details',
//     socialShare: true,
//   },
// ];
// const awardData = [
//   {
//     title: 'Malcolm Baldrige National Quality Award',
//     subTitle:
//       'This award recognizes healthcare organizations that have demonstrated excellence in leadership, strategic planning, customer and employee satisfaction, and operational efficiency.',
//     iconUrl: 'images/icons/award.svg',
//   },
//   {
//     title: 'HIMSS Davies Award',
//     subTitle:
//       'This award recognizes healthcare organizations that have used health information technology to improve patient outcomes and reduce costs.',
//     iconUrl: 'images/icons/award.svg',
//   },
//   {
//     title: 'Healthgrades National’s Best Hospital',
//     subTitle:
//       'This recognition is given to hospitals that have achieved high ratings for clinical quality and patient safety across multiple specialties and procedures.',
//     iconUrl: 'images/icons/award.svg',
//   },
//   {
//     title: 'Joint Commission Gold Seal of Approval',
//     subTitle:
//       'This recognition is given to hospitals that have met rigorous standards for patient safety and quality of care.',
//     iconUrl: 'images/icons/award.svg',
//   },
// ];
const departmentData = [
  {
    title: 'Emergency Department',
    iconUrl: 'images/home_1/department_icon_1.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Home Care Services',
    iconUrl: 'images/home_1/home-care.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Ambulance Services',
    iconUrl: 'images/home_1/ambulance.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Cardiology Department',
    iconUrl: 'images/home_1/department_icon_4.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Neurology Department',
    iconUrl: 'images/home_1/department_icon_5.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Gynecology Department',
    iconUrl: 'images/home_1/department_icon_3.svg',
    href: '/departments/department-details',
  },
];

export default function Home() {
  pageTitle('Home');
  return (
    <>
      <Hero
        title="Your Partner in Health and Wellness"
        subTitle="We are committed to providing you with the best medical and healthcare services to help you live healthier and happier."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/hero_img.png"
        buttonText="Book Now"
        buttonUrl="tel:+917977412580"
        infoList={[
          {
            title: 'Enquire Now',
            subTitle: '+91 79774 12580',
            iconUrl: '/images/contact/icon_1.svg',
          },
          {
            title: '24/7 Emergency Ambulance',
            subTitle: '+91 79774 12580',
            iconUrl: '/images/icons/ambulance.svg',
          },
          {
            title: 'Areas We Serve',
            subTitle: 'Mumbai',
            iconUrl: '/images/icons/pin.svg',
          },
        ]}
        btnText="Book Now"
        btnUrl="tel:+917977412580"
      />
      {/* Start Feature Section */}
      {/* <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Our Values" data={featureListData} />
      </Section> */}
      {/* End Feature Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Departments"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section>

      {/* End Departments Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/home_1/about.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          subTitle="CarePulse Health & Home Care"
          featureList={[
            {
              featureListTitle:
                'CarePulse is a team of experienced medical professionals',
              featureListSubTitle:
                'Dedicated to providing top-quality healthcare services. We believe in a holistic approach to healthcare that focuses on treating the whole person, not just the illness or symptoms.',
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Award Section */}
      {/* <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Departments" data={awardData} />
      </Section> */}
      {/* End Award Section */}
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogData}
        />
      </Section> */}
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section>
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      {/* <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection data={brandData} />
      </Section> */}
      {/* End Brand Section */}
    </>
  );
}
